export function getBrowserInfo() {
    if (
      typeof window === "undefined" &&
      typeof navigator === "undefined"
    ) {
      return {};
    }

    const searchParams = new URLSearchParams(window.location.search);

    return {
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      referrer: {
        type: document.referrer ? "internal" : "external",
        url: document.referrer,
        name: document.referrer,
        link: document.referrer,
      },
      page: {
        path: window.location.pathname,
        title: document.title,
        url: window.location.href,
        search: window.location.search,
        hash: window.location.hash,
        referrer: window.document.referrer,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      campaign: {
        source: searchParams.get("utm_source"),
        medium: searchParams.get("utm_medium"),
        term: searchParams.get("utm_term"),
        content: searchParams.get("utm_content"),
        campaign: searchParams.get("utm_campaign"),
      },
    };
  }

import $ from 'jquery'

function setTypeformParams() {
  const maxAttempts = 10
  let counter = 0
  const interval = setInterval(function () {
    // Verifying Segment is available
    if (window.analytics && typeof window.analytics.user === 'function') {
      clearInterval(interval)

      const anonymousId = window.analytics.user()?.anonymousId()
      const ctaBtn = $('.js-pre-purchase-style-typeform')
      const currentUrl = ctaBtn.attr('href')

      ctaBtn.attr('href', `${currentUrl}?anonymousid=${anonymousId}`)
    }
    counter++
    if (counter >= maxAttempts) {
      // Stop interval in case Segment is unavailable for any reason.
      clearInterval(interval)
    }
  }, 300)
}

export default function initTypeformFunctions() {
  $(function () {
    setTypeformParams()
  })
}

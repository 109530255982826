export default function initMarginControl() {
    const bfcdActive = document.getElementById('bfcd')
    

    function updateBfcdOffset() { 
        const bfcd = document.getElementById('bfcd')
        const main = document.getElementById('content')
        const currentPencilBanner = document.getElementById('pencil-banner')

        const bfcdHeight = currentPencilBanner ? bfcd.clientHeight + currentPencilBanner.clientHeight : bfcd.clientHeight
        main.style.marginTop = `${bfcdHeight}px`
    }
    
    if (bfcdActive) {
        updateBfcdOffset()
        window.onresize = updateBfcdOffset
    }
}
import $ from 'jquery'
const $doc = $(document)

function closeTippy(event) {
  event.preventDefault();
  $('.wp-block-getwid-image-hotspot__dot.is-visible[aria-expanded="true"]').click();
}

export default function initHotSpots(){
  $doc.on('click', '.js-close-tip', closeTippy )
}

import { getBrowserInfo } from './getBrowserInfo'
const writeKeys = {
  staging: 'wTIgrPqgEYJlk36yEktrJn810aL72bpi',
  prod: 'gRYfg14B9o0lNM52tMJBgtUW4QudM8pE',
}

const EventTypes = {
	Track: {
    name: "track",
    attributes: "properties",
  },
	Page:{
    name: "page",
    attributes: "properties",
  },
	Identify:{
    name: "identify",
    attributes: "traits",
  },
}

export class SegmentServerClient {
  constructor(baseUrl, yzsaCookie) {
    const env = window.location.hostname.includes('yardzendev')
      ? 'staging'
      : 'prod'
    this.writeKey = writeKeys[env]
    this.baseUrl = baseUrl
    this.yzsaCookie = yzsaCookie
  }

  async sendEvent(eventType, eventName, properties) {
    try {
      const payload = {
        event: eventName,
        anonymousId: this.yzsaCookie,
        writeKey: this.writeKey,
        [eventType.attributes]: properties,
        context: getBrowserInfo(),
      }

      const response = await fetch(`${this.baseUrl}/${eventType.name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        throw new Error(`HTTP error, status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error sending event:', error)
    }
  }

  identify(traits) {
    return this.sendEvent(EventTypes.Identify, 'Identify', traits)
  }

  track(eventName, properties) {
    return this.sendEvent(EventTypes.Track, eventName, properties)
  }

  page(properties) {
    return this.sendEvent(EventTypes.Page, 'Page View', {
      ...getBrowserInfo().page,
      ...properties,
    })
  }
}

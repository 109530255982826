export function updateRef (data, element, replace = false) {
  var serializedData = new URLSearchParams(data).toString() // Serialize the data
  var href = element.getAttribute('href')
  if (replace) {
    // Case for Typeform url with format:
    // https://yardzen.typeform.com/to/Dl52rJ1e#profileid=xxxxx&userid=xxxxx
    var newHref
    var param = Object.keys(data)[0]
    var urlSplit = href.split(`${param}=`)
    if (urlSplit.length < 2) newHref = href
    else newHref = `${urlSplit[0]}${serializedData}`
  } else {
    if (href.includes('?')) {
      // Append serialized data with '&' to the href
      var newHref = href + '&' + serializedData
    } else {
      // Append serialized data with '?' to the href
      var newHref = href + '?' + serializedData
    }
  }

  element.setAttribute('href', newHref)
}

import $ from 'jquery'
const $doc = $(document)

function copyWebsite() {
  navigator.clipboard.writeText(window.location.href).then(() => {
    console.log('link copied');
  }, () => {
    console.log('link not copied');
  });
}

export default function initCopyWebsite(){
  $doc.on('click', '.js-copy-website', copyWebsite )
}
